import HistoryDateRangePickers from "./HistoryDateRangePickers";

export const ALL = "All";
export const YESTERDAY = "Yesterday";
export const LAST_7_DAYS = "Last 7 days";
export const LAST_30_DAYS = "Last 30 days";
export const CUSTOM_RANGE = "custom_range";

export const defaultDateRange = ALL;
export const defaultDateRangeDaysLimit = 185;
export const dateRanges = [
  { displayText: ALL, value: ALL },
  { displayText: YESTERDAY, value: YESTERDAY },
  { displayText: LAST_7_DAYS, value: LAST_7_DAYS },
  { displayText: LAST_30_DAYS, value: LAST_30_DAYS },
  {
    displayText: "Custom Range   \t⏷",
    value: CUSTOM_RANGE,
    component: HistoryDateRangePickers,
  },
];