import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { defaultDateRangeDaysLimit } from "./dateRanges";
import "../../components/Dropdown/datepicker.css";

const HistoryDateRangePickers = (props) => {
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const toDatePicker = useRef(null); // Using useRef for handling ref

  const handleChangeStart = (date) => {
    setStartDate(date);

    // If endDate is null, keep the "end" date picker open
    if (!endDate && toDatePicker.current) {
      toDatePicker.current.setOpen(true);
    }

    // Adjust end date if it's out of the 185-day range
    if (endDate && moment(date).add(defaultDateRangeDaysLimit, 'days').isBefore(endDate)) {
      setEndDate(moment(date).add(defaultDateRangeDaysLimit, 'days').toDate());
    }
  };

  const handleChangeEnd = (date) => {
    setEndDate(date);
  };

  useEffect(() => {
    if (startDate && endDate && props.isVisible) {
      // Prepare options and pass to parent via props.handleSelect
      const options = { start_time: startDate, end_time: endDate };
      props.handleSelect("custom_range", options);
    }
  }, [startDate, endDate, props.isVisible]);

  return (
    <div 
      className={`${props.isVisible ? 'display-flex' : 'display-none'} margin-top-10px menu-datepicker-container position-absolute`}
      style={{ marginLeft: "-5px", left: "50%", transform: "translateX(-50%)", background: 'transparent' }}>
      <DatePicker
        selected={startDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        onChange={handleChangeStart}
        maxDate={endDate ? moment(endDate).toDate() : moment().add(defaultDateRangeDaysLimit, 'days').toDate()} // Ensure max range
        filterDate={
          props.future === false
            ? (date) => moment().isAfter(date)
            : (date) => moment().isBefore(date)
        }
      />
      <span className="margin-top-bottom-auto margin-left-right-5px quinary-text-color opacity-50">-</span>
      <DatePicker
        ref={toDatePicker}
        selected={endDate}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        onChange={handleChangeEnd}
        minDate={startDate} // Ensure end date is after start date
        maxDate={startDate ? moment(startDate).add(defaultDateRangeDaysLimit, 'days').toDate() : null} // Limit to 185 days from start
        openToDate={endDate != null ? endDate: startDate}
        filterDate={
          props.future === false
            ? (date) => moment().isAfter(date)
            : (date) => moment().isBefore(date)
        }
      />
    </div>
  );
};

HistoryDateRangePickers.defaultProps = {
  future: false
}

export default HistoryDateRangePickers;