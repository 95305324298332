import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/PageTitle/index";
import { Button, Grid } from "@material-ui/core";

const download_icon = require("../../../assets/icons/download_icon.svg");

const MenuBar = (props) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const isMobileScreen = window.innerWidth < 600;

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const threshold = isMobileScreen ? 230 : 130; // Adjust threshold for mobile
    setIsScrolled(scrollY > threshold);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <>
    {isMobileScreen && isScrolled ? (<div style={{height: "36px"}}>dummy text</div>) : ''}
    <div
      className={`home-page-menu-bar z-index-1 ${ isScrolled ? "floating" : "" }`}
      style={{ top: isScrolled ? (isMobileScreen ? 60 : 8) : 0, position: isScrolled ? "sticky" : 'relative' }}
    >
      <Grid container>
        {!isScrolled ? 
        <Grid item xs={12}>
          <PageTitle pageTitle={props.title} />
        </Grid>
        : ''
        }
        {props.showFilterByToggle && (
          <>
          <p className="filter-by-toggle-description margin-top-20px">Fetch data based on:</p>
          <Grid item xs={12} className={`display-flex`}>
            <div className="filter-by-toggle-container">
              <Button
                  className={`filter-by-toggle-button ${props.selectedFilter === "invoices" ? "selected" : ""}`}
                  onClick={() => props.onToggle("invoices")}
              >
                  Invoices
              </Button>
              <Button
                  className={`filter-by-toggle-button ${props.selectedFilter === "transactions" ? "selected" : ""}`}
                  onClick={() => props.onToggle("transactions")}
              > 
                  Transactions
              </Button>
            </div>
          </Grid>
          </>
          )}
        <Grid item xs={12} className={`display-flex ${isScrolled ? '' : "margin-top-24px"}`}>
          {props.showDateRangeButtons && props.dateRanges.map((dateRangeObj, index) => (
            <>
              {dateRangeObj.component ? (
                <div className="position-relative">
                  <Button
                    key={index}
                    className={`date-range-btn font-size-12 margin-right-10px ${props.dateRange == dateRangeObj.value ? "selected" : ""}`}
                    onClick={() => props.dateChangeHandler(dateRangeObj.value)}
                  >
                    {dateRangeObj.displayText}
                  </Button>
                  {React.createElement(dateRangeObj.component, props.specialBtnProps)}
                </div>
              ) : (
                <Button
                  key={index}
                  className={`date-range-btn font-size-12 margin-right-10px ${props.dateRange == dateRangeObj.value ? "selected" : ""}`}
                  onClick={() => props.dateChangeHandler(dateRangeObj.value)}
                >
                  {dateRangeObj.displayText}
                </Button>
              )}
            </>
          ))}
          {props.showDownloadButton ? 
            <div onClick={props.downloadButtonHandler} className={`display-flex align-items-center cursor-pointer smooth-click ${props.downloadButtonDisabled ? 'click-disabled' : ''}`} style={{ color: "#4a586b", marginLeft: "auto", marginRight: "30px" }}>
              <span className="font-weight-450" style={{ whiteSpace: "nowrap" }}>{props.downloadButtonText}{" "}</span>
                {props.showAnimatedDots ? 
                  <>
                    <span className="dot">.</span>
                    <span className="dot">.</span>
                    <span className="dot">.</span>
                  </>
                : ''}
              <img style={{ padding: "5px", borderRadius: "50px", background: "white", marginLeft: "10px" }} src={download_icon} alt=""/>
            </div> : ''
          }
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default MenuBar;
